/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Subtitle, Title, SeparateLine, SeparateLineWrap, Text } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"David Klema - Elektromontážní práce - Služby"} description={"Provádíme veškeré elektroinstalace a elektroinstalační práce, od dodávky materiálu, až po finální montáž elektroinstalace."} seoTitle={"Odbornost je naší prioritou"}>
        <SiteHeader set="w3paydrlcin" currentLanguage={undefined} />

        <Column className="css-dk50o0 css-16ilh4h --style2 --full" name={"uvod"} fullscreen={true} css={css`
      
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/38215/20ceaca5d7cc4a478158f4f148e510c1_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/38215/20ceaca5d7cc4a478158f4f148e510c1_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/38215/20ceaca5d7cc4a478158f4f148e510c1_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/38215/20ceaca5d7cc4a478158f4f148e510c1_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/38215/20ceaca5d7cc4a478158f4f148e510c1_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/38215/20ceaca5d7cc4a478158f4f148e510c1_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/38215/20ceaca5d7cc4a478158f4f148e510c1_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/38215/20ceaca5d7cc4a478158f4f148e510c1_s=3000x_.jpg);
    }
  
background-position: 56% 35%;
    `}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" columns={"1"}>
            
            <ColumnWrapper className="js-anim  --anim6 --anim-s5 --center pl--0" style={{"maxWidth":""}} anim={"6"} animS={"5"}>
              
              <Subtitle className="subtitle-box fs--26 w--600 swpf--uppercase ls--50" content={"<span style=\"color: white;\">David Klema</span>"}>
              </Subtitle>

              <Title className="title-box fs--43 w--500 lh--1" content={"<span style=\"color: var(--white);\">Odbornost je naší prioritou pro bezpečné prostředí nejen Vašeho domova.&nbsp;</span><br>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <SeparateLine style={{"paddingBottom":0,"paddingTop":0}} name={"bdjzgu9jkib"} layout={"l3"}>
          
          <SeparateLineWrap >
          </SeparateLineWrap>

        </SeparateLine>


        <Column className="--center" style={{"marginTop":0,"paddingTop":18,"paddingBottom":36}} name={"sfp0qyabfv"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box title-box--center fs--43" content={"Služby"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60" style={{"paddingTop":19}} name={"yzbqimlbj3k"}>
          
          <ColumnWrap className="column__flex --center el--3 flex--center" columns={"3"}>
            
            <ColumnWrapper >
              
              <Title className="title-box fs--30" content={"<span style=\"font-weight: bold;\">Realizace elektroinstalací</span>"}>
              </Title>

              <Text className="text-box text-box--left fs--20" content={"<span style=\"font-weight: bold;\">Rodinných domů<br>Bytů\n<br>Bytových jader\n<br>Kancelářských prostorů\n<br>Průmyslových prostorů<br>Nebytových prostorů<br></span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Title className="title-box fs--30" content={"<span style=\"font-weight: bold;\">Realizace strukturované kabeláže</span>"}>
              </Title>

              <Text className="text-box text-box--left fs--20" content={"<span style=\"font-weight: bold;\">Konfigurace síťových zařízení\n<br>Realizace kabeláže<br>Montáže RACK skříní<br>Instalace bezdrátových vysílačů (AP/router)<br>Výměna stávajících komponentů<br>Diagnostika strukturované kabeláže</span><br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Title className="title-box fs--30" content={"<span style=\"font-weight: bold;\">Opravy stávající elektroinstalace</span><br>"}>
              </Title>

              <Text className="text-box text-box--left fs--20" content={"<span style=\"font-weight: bold;\">Opravy stávajících kabelových rozvodů<br>Rekonstrukce&nbsp; rozvaděčů<br>Rekonstrukce elektroměrových rozvaděčů\n<br>Rozšíření zásuvkových a světelných obvodů<br>Zřizování Hlavního ochranného pospojování (HOP) - Hromosvody (LPS)</span><br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--3 flex--center" columns={"3"}>
            
            <ColumnWrapper >
              
              <Title className="title-box fs--30" content={"<span style=\"font-weight: bold;\">Instalace</span>"}>
              </Title>

              <Text className="text-box text-box--left fs--20" content={"<span style=\"font-weight: bold;\">Instalace osvětlení\n<br>Instalace nouzového osvětlení<br>Zapojení kuchyňských spotřebičů<br>Zapojení průmyslových strojů<br>Zapojení bojlerů<br></span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Title className="title-box fs--30" content={"<span style=\"font-weight: bold;\">Fotovoltaické elektrárny</span>"}>
              </Title>

              <Text className="text-box text-box--left fs--20" content={"<span style=\"font-weight: bold;\">Kompletní instalace FVE systému<br>Instalace dobíjecích stanic - Wallboxů<br>Vyřízení dotací a kompletní administrativy<br>Servis FVE systému a jejich příslušenství<br>Odborný dohled prováděné instalace</span><br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Title className="title-box fs--30" content={"<span style=\"font-weight: bold;\">Zabezpečovací systémy</span>"}>
              </Title>

              <Text className="text-box text-box--left fs--20" content={"<span style=\"font-weight: bold;\">Montáž zabezpečovacího systému Jablotron<br>Montáže kamerového systému<br>Montáže a servis kontroly vstupu<br>Montáže a servis docházkového systému<br>Úpravy stávající zabezpečovací technologie<br></span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <SeparateLine style={{"paddingBottom":0,"paddingTop":0}} name={"gxojmhbn2ti"} layout={"l3"}>
          
          <SeparateLineWrap style={{"paddingBottom":0,"paddingTop":0}}>
          </SeparateLineWrap>

        </SeparateLine>


        <Column style={{"paddingTop":0,"paddingBottom":0}} name={"pzw65px1aj"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
          </ColumnWrap>

        </Column>


        <Column style={{"paddingTop":20,"paddingBottom":0}} layout={"l1"} name={"ugynnzz2v7"}>
          
          <ColumnWrap className="column__flex --left el--2" columns={"2"}>
            
            <ColumnWrapper >
              
              <Title className="title-box fs--30" content={"David Klema - Elektromontážní práce"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper className="--left">
              
              <Title className="title-box fs--30" content={"Kontakt"}>
              </Title>

              <Text className="text-box fs--18" content={"<span style=\"font-weight: bold; color: var(--white);\">Tovární 2446/2\n<br>Karviná Nové - Město\n<br>735 06<br>+420 739 294 592<br>davidklema@seznam.cz<br></span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column style={{"paddingTop":0,"paddingBottom":0}} layout={"l1"} name={"ouvzadr32wr"}>
        </Column>

      </Layout>
    </ThemeWrapper>
  )
}